function createItem(key, text, path, icon, items, expanded) {
  return {
    key,
    text,
    path,
    icon,
    items,
    expanded
  }
}

export default [
  createItem(null, 'Home', '/home', 'fa-solid fa-house'),
  createItem(null, 'Master', null, 'fa-solid fa-star', [
    createItem('barang', 'Barang', '/barang'),
    createItem('brand', 'Brand', '/brand'),
    createItem('bank', 'Bank', '/bank'),
    createItem('gudang', 'Gudang', '/gudang'),
    createItem('jabatan', 'Jabatan', '/jabatan'),
    createItem('pengguna', 'Pengguna', '/pengguna'),
    createItem('departemen', 'Departemen', '/departemen'),
    createItem('kategori_barang', 'Kategori Barang', '/kategori_barang'),
    createItem('area_sales', 'Area Sales', '/area_sales'),
    createItem('sales', 'Sales', '/sales'),
    createItem('giro', 'Giro', '/giro'),
    createItem('ekspedisi', 'Ekspedisi', '/ekspedisi'),
    createItem('edc', 'EDC', '/edc'),
    createItem('kartu_bank', 'Kartu Bank', '/kartu_bank'),
    createItem('supplier', 'Supplier', '/supplier'),
    createItem('vendor', 'Vendor', '/vendor'),
    createItem('pelanggan', 'Pelanggan', '/pelanggan'),
    createItem('kategori_pelanggan', 'Kategori Pelanggan', '/kategori_pelanggan'),
    createItem('kategori_akun', 'Kategori Akun', '/kategori_akun'),
    createItem('akun', 'Akun', '/akun'),
    createItem('tarif_pajak', 'Tarif Pajak', '/tarif_pajak'),
    createItem('karyawan', 'Karyawan', '/karyawan'),
    createItem('petugas', 'Petugas', '/petugas'),
    createItem('kategori_property', 'Kategori Property', '/kategori_property'),
    createItem('jenis_harga_jual', 'Jenis Harga Jual', '/jenis_harga_jual'),
    createItem('property', 'Property', '/property'),
    createItem('tarif_billing', 'Tarif Billing', '/tarif_billing'),
    createItem('lokasi', 'Lokasi', '/lokasi'),
    createItem('addon', 'Addon', '/addon'),
    createItem('ongkir', 'Ongkir', '/ongkir'),
  ]),
  
  createItem('gaji', 'Gaji', null, 'fa-solid fa-users', [
    createItem('komponen_gaji', 'Komponen Gaji', '/komponen_gaji'),
    createItem('slip_gaji', 'Slip Gaji', '/slip_gaji'),
  ]),

  createItem('poin', 'Poin', null, 'fa-solid fa-gift', [
    createItem('poin', 'Daftar Poin', '/poin'),
    createItem('poin_pakai', 'Pemakaian Poin', '/poin_pakai'),
  ]),

  createItem(null, 'Sales', null, 'fa-solid fa-person-rays', [
    createItem('rep_kegiatan', 'Kegiatan', '/rep/kegiatan'),
    createItem('promo', 'Promo', '/promo'),
  ]),
  createItem(null, 'Project', null, 'fa-solid fa-tasks', [
    createItem('spk', 'SPK', '/spk'),
    createItem('survey', 'Survey SPK', '/survey'),
    createItem('rab', 'RAB SPK', '/rab'),
    createItem('penawaran', 'Penawaran SPK', '/penawaran'),
    createItem('surat_pesanan', 'Surat Pesanan SPK', '/surat_pesanan'),
    createItem('spk_barang', 'Barang SPK', '/spk_barang'),
    createItem('spk_biaya', 'Biaya SPK', '/spk_biaya'),
    createItem('bayar_spk_biaya', 'Bayar Biaya SPK', '/bayar_spk_biaya'),
    createItem('pengerjaan', 'Pengerjaan SPK', '/pengerjaan'),
    createItem('serah_terima', 'Serah Terima SPK', '/serah_terima'),
    createItem('invoice_spk', 'Invoice SPK', '/invoice_spk'),
    createItem('bayar_spk', 'Pembayaran SPK', '/bayar_spk'),
    createItem('rep_aktivitas_spk', 'Aktivitas SPK', '/rep/aktivitas_spk'),
    createItem('spk_selesai', 'Penyelesaian SPK', '/spk_selesai'),
    createItem('rep_barang_biaya_spk', 'Laporan Barang + Biaya SPK', '/rep_barang_biaya_spk'),
  ]),
  createItem(null, 'Admin', null, 'fa-solid fa-user', [
    createItem('sales_order', 'Sales Order', '/sales_order'),
    createItem('jual', 'Penjualan', '/jual'),
    createItem('jual_proforma', 'Penjualan Proforma', '/jual_proforma'),
    createItem('purchase_order', 'Purchase Order', '/purchase_order'),
    createItem('beli', 'Pembelian', '/beli'),
    createItem('pricelist', 'Pricelist', '/pricelist'),
  ]),
  createItem(null, 'Gudang', null, 'fa-solid fa-truck', [
    createItem('terima_barang', 'Penerimaan Barang', '/terima_barang'),
    createItem('titip_barang', 'Penitipan Barang', '/titip_barang'),
    createItem('delivery_order', 'Delivery Order [DO]', '/delivery_order'),
    createItem('pakai_barang', 'Pemakaian Barang', '/pakai_barang'),
    createItem('mutasi_ag', 'Mutasi Antar Gudang', '/mutasi_ag'),
    createItem('konversi_barang', 'Konversi Barang', '/konversi_barang'),
    createItem('stok_opname', 'Stok Opname', '/stok_opname'),
    createItem('stok_opname_simple', 'Stok Opname Simple', '/stok_opname_simple'),
    createItem('kartu_stok', 'Kartu Stok', '/rep/kartu_stok'),
  ]),
  createItem(null, 'Finance', null, 'fa-solid fa-landmark', [
    createItem('hutang_karyawan', 'Hutang Karyawan', '/hutang_karyawan'),
    createItem('bayar_hutang_karyawan', 'Bayar Hutang Karyawan', '/bayar_hutang_karyawan'),
    createItem('bayar_hutang', 'Bayar Hutang', '/bayar_hutang'),
    createItem('bayar_piutang', 'Bayar Piutang', '/bayar_piutang'),
    createItem('hutang', 'Daftar Hutang', '/hutang'),
    createItem('hutang_biaya', 'Daftar Hutang Biaya', '/hutang_biaya'),
    createItem('piutang', 'Daftar Piutang', '/piutang'),
    createItem('rekap_pelanggan', 'Rekap Pelanggan', '/rekap_pelanggan'),
  ]),
  createItem(null, 'General Cashier', null, 'fa-solid fa-calculator', [
    createItem('biaya', 'Entri Biaya', '/biaya'),
    createItem('bkm', 'Bukti Kas Masuk [BKM]', '/bkm'),
    createItem('bkk', 'Bukti Kas Keluar [BKK]', '/bkk'),
  ]),
  createItem(null, 'Accounting', null, 'fa-solid fa-dog', [
    createItem('hitung_hpp', 'Hitung HPP', '/fix_hpp'),
    createItem('fix_hpp_task', 'Hitung HPP 2', '/fix_hpp_task'),
    createItem('hpp_adjustment', 'HPP Adjustment', '/hpp_adjustment'),
    createItem('daftar_hpp', 'Daftar HPP', '/rep/daftar_hpp'),
    createItem('mutasi_hpp', 'Mutasi HPP', '/rep/mutasi_hpp'),
    createItem('kartu_stok_hpp', 'Kartu Stok HPP', '/rep/kartu_stok_hpp'),
    createItem('jurnal', 'Jurnal', '/jurnal'),
    createItem('daftar_jurnal', 'Daftar Jurnal', '/rep/daftar_jurnal'),
    createItem('ledger', 'Ledger', '/rep/ledger'),
    createItem('kartu_piutang', 'Kartu Piutang', '/rep/kartu_piutang'),
    createItem('kartu_hutang', 'Kartu Hutang', '/rep/kartu_hutang'),
    createItem('kelompok_aset', 'Kelompok Aset', '/kelompok_aset'),
    createItem('aset', 'Aset', '/aset'),
    createItem('penyusutan', 'Daftar Penyusutan', '/penyusutan'),
    createItem('pindah_kas', 'Pindah Kas', '/pindah_kas'),
    createItem('rekon_bank', 'Rekon Bank', '/rekon_bank'),
  ]),

  // negara
  createItem(null, 'Negara', null, 'fa-solid fa-flag', [
    createItem('lawan_transaksi', 'Lawan Transaksi', '/lawan_transaksi'),
    createItem('barang_efaktur', 'Barang EFaktur', '/barang_efaktur'),
    createItem('faktur_masukan', 'Faktur Masukan', '/faktur_masukan'),
    createItem('faktur_keluaran', 'Faktur Keluaran', '/faktur_keluaran'),
  ]),

  createItem(null, 'Pendapatan Lain', null, 'fa-solid fa-hand-holding-dollar', [
    createItem('titip_transfer', 'Titip Transfer', '/titip_transfer'),
    createItem('tarik_tunai', 'Tarik Tunai', '/tarik_tunai'),
    createItem('rep_titip_transfer_tarik_tunai', 'Laporan Titip Transfer + Tarik Tunai', '/rep/titip_transfer_tarik_tunai'),
    createItem('servis', 'Servis', '/servis'),
    createItem('servis_bayar', 'Pembayaran Servis', '/servis_bayar'),
  ]),

  // peralatan
  createItem(null, 'Peralatan', null, 'fa-solid fa-tools', [
    createItem('fix_data', 'Perbaiki Data', '/fix_data'),
    createItem('schedule', 'Schedule', '/schedule'),
    createItem('trusted_device', 'Trusted Device', '/trusted_device'),
  ]),

  // other
  createItem(null, 'Other', null, 'fa-solid fa-flask', [
    createItem('garansi', 'Garansi', '/garansi'),
    createItem('resep', 'Resep', '/resep'),
  ]),

  // laporan
  createItem(null, 'Laporan', null, 'fa-solid fa-cloud', [
    createItem('rep_purchase_order', 'Purchase Order', '/rep/purchase_order'),
    createItem('rep_rekap_jual', 'Rekap Penjualan', '/rep/rekap_jual'),
    createItem('rep_rekap_kasir', 'Rekap Kasir', '/rep/rekap_kasir'),
    createItem('rep_rekap_gudang', 'Rekap Gudang', '/rep/rekap_gudang'),
    createItem('rep_rekap_hutang', 'Rekap Hutang', '/rep/rekap_hutang'),
    createItem('rep_rekap_piutang', 'Rekap Piutang', '/rep/rekap_piutang'),
    createItem('rep_stok', 'Stok', '/rep/stok'),
    createItem('rep_stok_hpp', 'Stok HPP', '/rep/stok_hpp'),
    createItem('rep_jual', 'Penjualan', '/rep/jual'),
    createItem('rep_jual_proforma', 'Penjualan Proforma', '/rep/jual_proforma'),
    createItem('rep_beli', 'Pembelian', '/rep/beli'),
    createItem('rep_terima_barang', 'Penerimaan Barang', '/rep/terima_barang'),
    createItem('rep_delivery_order', 'Delivery Order [DO]', '/rep/delivery_order'),
    createItem('rep_pakai_barang', 'Pemakaian Barang', '/rep/pakai_barang'),
    createItem('rep_mutasi_ag', 'Mutasi AG', '/rep/mutasi_ag'),
    createItem('rep_titip_barang', 'Penitipan Barang', '/rep/titip_barang'),
    createItem('rep_laba_kotor', 'Laba Kotor', '/rep/laba_kotor'),
    createItem('rep_laba_kotor_lunas', 'Laba Kotor [Lunas]', '/rep/laba_kotor_lunas'),
    createItem('rep_stok_realtime', 'Stok Realtime', '/rep/stok_realtime'),
    createItem('rep_rekap_jual_barang', 'Rekap Penjualan Barang', '/rep/rekap_jual_barang'),
    createItem('rep_rekap_jual_proforma', 'Rekap Penjualan Proforma', '/rep/rekap_jual_proforma'),
    createItem('neraca_saldo', 'Neraca Saldo', '/rep/neraca_saldo'),
    createItem('neraca', 'Neraca', '/rep/neraca'),
    createItem('laba_bersih', 'Laba Bersih', '/rep/laba_bersih'),
    createItem('rep_bayar_piutang', 'Pembayaran Piutang', '/rep/bayar_piutang'),
    createItem('rep_bayar_hutang', 'Pembayaran Hutang', '/rep/bayar_hutang'),
    createItem('rep_aktivitas', 'Aktivitas', '/rep/aktivitas'),
    createItem('rep_bayar_jual', 'Pembayaran Penjualan', '/rep/bayar_jual'),
  ]),

  createItem(null, 'Setting', null, 'fa-solid fa-gear', [
    createItem('setting_akun', 'Akun', '/setting/akun'),
    createItem('setting_no_dokumen', 'No Dokumen', '/setting/no_dokumen'),
    createItem('setting_aplikasi', 'Aplikasi', '/setting/aplikasi'),
    createItem('setting_android', 'Android', '/setting/android'),
    createItem('setting_poin', 'Poin', '/setting/poin'),
  ]),
];
