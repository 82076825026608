import axios from "axios";

class ApiHelper {
  static SERVER_URL = 'http://localhost:8000'
  static TOKEN = ''

  static baseUrl() {
    return this.SERVER_URL + '/api/admin'
  }

  static baseUrlFile() {
    return this.SERVER_URL + '/api/file/'
  }

  static getImageUrl() {
    return this.SERVER_URL + '/api/file'
  }

  static post(url, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${this.baseUrl()}/${url}`, data, {
          headers: {
            token: this.TOKEN,
            device_token: localStorage.getItem("device_token"),
          }
        })
        .then((res) => {
          let api = res.data;
          if (!api.ok) {
            reject(api.msg);
          } else {
            resolve(api.data);
          }
        })
        .catch((e) => reject(e));
    });
  }
  static postFile(url, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${this.baseUrl()}/${url}`, data, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: this.TOKEN,
            device_token: localStorage.getItem("device_token"),
          }
        })
        .then((res) => {
          let api = res.data;
          if (!api.ok) {
            reject(api.msg);
          } else {
            resolve(api.data);
          }
        })
        .catch((e) => reject(e));
    });
  }
}

export default ApiHelper;
