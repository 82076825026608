// import akses from "./akses";
import ApiHelper from "./helper/api-helper";

// const defaultUser = {
//   nama: "sandra@example.com",
//   avatarUrl:"https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/employees/06.png",
// };

export default {
  daftar_akses: [],
  _user: null,

  loggedIn() {
    return this._user != null
  },

  boleh(akses) {
    if (this._user?.is_admin) {
      return true
    }
    let itm = this._user.konfigurasi?.find(x => x == akses)
    return !!itm
  },

  async logIn(user_name, password, remember_me) {
    try {
      // Send request
      let x = await ApiHelper.post("login", {
        user_name,
        password,
        remember_me,
      });
      let user = {
        nama: x.nama,
        user_name: x.user_name,
        token: x.token,
        is_admin: x.is_admin == 1,
        konfigurasi: x.konfigurasi,
      }
      this._user = user
      ApiHelper.TOKEN = user.token;
      this.daftar_akses = user.konfigurasi || []
      localStorage.setItem('user', JSON.stringify(this._user))
      return {
        isOk: true,
        data: user,
      };
    } catch (e) {
      return {
        isOk: false,
        message: "Authentication failed\n" + e,
      };
    }
  },

  logOut() {
    ApiHelper.post('logout')
      .then(() => {

      })
      .catch(e => console.error(e))
      .finally(() => {
        localStorage.clear()
        ApiHelper.TOKEN = ''
        this._user = null
        this.daftar_akses = []
      })
  },

  getUser() {
    try {
      return {
        isOk: true,
        data: this._user,
      };
    } catch {
      return {
        isOk: false,
      };
    }
  },

  async resetPassword(email) {
    try {
      // Send request
      console.log(email);

      return {
        isOk: true,
      };
    } catch {
      return {
        isOk: false,
        message: "Failed to reset password",
      };
    }
  },

  async changePassword(email, recoveryCode) {
    try {
      // Send request
      console.log(email, recoveryCode);

      return {
        isOk: true,
      };
    } catch {
      return {
        isOk: false,
        message: "Failed to change password",
      };
    }
  },

  async createAccount(email, password) {
    try {
      // Send request
      console.log(email, password);

      return {
        isOk: true,
      };
    } catch {
      return {
        isOk: false,
        message: "Failed to create account",
      };
    }
  },

  restoreLogin() {
    let u_string = localStorage.getItem('user') || ''
    if (u_string.length == 0) {
      this._user = null
    } else {
      try {
        this._user = JSON.parse(u_string)
        this.daftar_akses = this._user.konfigurasi || []
        ApiHelper.TOKEN = this._user.token;
        return true
      } catch (er) {
        console.error(er)
        this._user = null
      }
    }
    return false
  }
};
